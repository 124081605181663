import NavFactory from "../../../library/NavFactory";

let routes = [
    {
        name: 'Master',
        icon: 'account_balance',
        permission: 'staff-create',
        header: true,
        children: [
            {
                name: 'Account',
                icon: 'account_balance',
                permission: 'staff-create',
                children: [
                    {
                        name: 'Voucher Types',
                        path: 'journal_types',
                        icon: 'confirmation_number',
                        permission: 'subject-read',
                    },
                    {
                        name: 'Account Group',
                        path: 'account-head',
                        icon: 'manage_accounts',
                        permission: 'subject-read',
                    },
                    {
                        name: 'Account Ledger',
                        path: 'ledger-head',
                        icon: 'subject',
                        permission: 'subject-read',
                    },
                    {
                        name: 'Customer',
                        path: 'customer',
                        icon: 'supervisor_account',
                        permission: 'subject-read',
                    }
                ]
            },
            {
                name: 'Inventory',
                icon: 'account_balance',
                callBackFunc: NavFactory.isDev,
                children: [
                    {
                        name: 'WareHouse',
                        path: 'warehouse',
                        icon: 'warehouse',
                        permission: 'school-create',
                    },
                    {
                        name: 'Category',
                        path: 'category',
                        icon: 'category',
                        permission: 'school-create',
                    },
                    {
                        name: 'Unit',
                        path: 'unit',
                        icon: 'balance',
                        permission: 'school-create',
                    },
                    {
                        name: 'Item',
                        path: 'item',
                        icon: 'account_tree',
                        permission: 'school-create',
                    }
                ]
            }
        ]
    },
    {
        name: 'Entry',
        icon: 'book',
        permission: 'staff-create',
        header: true,
        children: [
            {
                name: 'Account',
                icon: 'book',
                permission: 'staff-create',
                children: [{
                    name: 'Billing Book',
                    path: 'billing-book',
                    icon: 'book',
                    permission: 'subject-read'
                }, {
                    name: 'Journal Entry',
                    path: 'journal-entry',
                    icon: 'vertical_split',
                    permission: 'subject-read'
                }, 
                {
                    name: 'Payment Entry',
                    path: 'general-payment',
                    icon: 'message',
                    permission: 'subject-read',
                }, 
                {
                    name: 'Receipt Entry',
                    path: 'general-receipt',
                    icon: 'art_track',
                    permission: 'subject-read',
                }, 
                {
                    name: 'Debit Note Entry',
                    path: 'dr-note',
                    icon: 'credit_score',
                    permission: 'subject-read',
                }, {
                    name: 'Credit Note Entry',
                    path: 'cr-note',
                    icon: 'payment',
                    permission: 'subject-read',
                }]
            },
            {
                name: 'Inventory',
                icon: 'book',
                callBackFunc: NavFactory.isDev,
                children: [
                    {
                        name: 'Purchase',
                        path: 'purchase',
                        icon: 'request_quote',
                        permission: 'school-create',
                    }, {
                        name: 'Purchase Return',
                        path: 'purchase-return',
                        icon: 'undo',
                        permission: 'school-create',
                    }, {
                        name: 'Sales',
                        path: 'sales',
                        icon: 'point_of_sale',
                        permission: 'school-create',
                    }, {
                        name: 'Sales Return',
                        path: 'sales-return',
                        icon: 'undo',
                        permission: 'school-create',
                    },
                ]
            },
        ]

    },
    {
        name: 'Report',
        icon: 'line_style',
        permission: 'staff-create',
        header: true,
        children: [{
            name: 'Account',
            icon: 'receipt',
            header: true,
            children: [{
                name: 'Journal Entry Book',
                path: 'entry-list',
                icon: 'vertical_split',
                permission: 'subject-read',
            }, {
                name: 'Account Ledger Report',
                path: 'account-statement',
                icon: 'payment',
                permission: 'subject-read',
            }, {
                name: 'Opening Balance Report',
                path: 'opening-balance-report',
                icon: 'payment',
                permission: 'subject-read',
            }, {
                name: 'Income/Expense Report',
                path: 'income-expense-report',
                icon: 'account_balance',
                permission: 'subject-read',
            }]
        },
        {
            name: 'Inventory',
            icon: 'receipt',
            header: true,
            callBackFunc: NavFactory.isDev,
            children: [{
                name: 'Purchase Report',
                path: 'purchase-report',
                icon: 'storefront',
                permission: 'subject-read',
            }, {
                name: 'Purchase Return Report',
                path: 'purchase-return-report',
                icon: 'keyboard_return',
                permission: 'subject-read',
            }, {
                name: 'Sales Report',
                path: 'sales-report',
                icon: 'speaker_notes',
                permission: 'subject-read',
            }, {
                name: 'Sales Return Report',
                path: 'sales-return-report',
                icon: 'call_missed',
                permission: 'subject-read',
            }]
        }
        ]
    },
    {
        name: 'Final Report',
        icon: 'receipt',
        permission: 'subject-read',
        header: true,
        children: [{
            name: 'Trial Balance',
            path: 'trial-balance',
            icon: 'receipt',
            permission: 'subject-read',
        }, {
            name: 'P/L Account',
            path: 'pl-account',
            icon: 'list_alt',
            permission: 'subject-read',
        }, {
            name: 'Balance Sheet',
            path: 'balance-sheet',
            icon: 'list_alt',
            permission: 'subject-read',
        }]
    },
    {
        name: 'Fiscal Year',
        icon: 'horizontal_split',
        permission: 'staff-create',
        header: true,
        children: [{
            name: 'Fiscal Year',
            path: 'fiscal-year',
            icon: 'payment',
            permission: 'subject-read',
        }]
    },
    {
        name: 'Post Opening',
        icon: 'horizontal_split',
        permission: 'staff-create',
        header: true,
        children: [{
            name: 'Post Opening ',
            path: 'post-opening',
            icon: 'payment',
            permission: 'subject-read',
        }]
    },
    // {
    //     name: 'School Payment',
    //     icon: 'double_arrow',
    //     path: 'school-payment',
    //     permission: true,
    //     header: true,
    //     callBackFunc: NavFactory.isSuper,
    // }

];

export default routes;