import authenticationMenu from './authentication/_routes/menu'
import dashboardMenu from './dashboard/_routes/menu'

import accountMenu from './account/_routes/menu'
import mapFeeHead from './mapfeehead/_routes/menu'
import inventory from './inventory/_routes/menu'


let menus = [...dashboardMenu, ...accountMenu, ...authenticationMenu, ...mapFeeHead, ...inventory];

export default menus;