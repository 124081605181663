<template>
  <v-app>
    <v-navigation-drawer
      persistent
      v-model="drawer"
      fixed
      clipped
      class="white lighten-6"
      app
    >
      <side-bar />
    </v-navigation-drawer>
    <v-app-bar color="blue darken-3" dark app dense fixed :clipped-left="true">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip color="primary" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="convertDateModal = true"
              >date_range</v-icon
            >
          </template>
          <span>Date Converter</span>
        </v-tooltip>
        <v-menu offset-y transition="slide-y-reverse-transition">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              {{ accounts.selectedFiscalYear.text }}
              <v-icon ml-2 v-if="accounts.fiscalYears.length > 1"
                >arrow_drop_down</v-icon
              >
            </v-btn>
          </template>
          <v-list v-if="accounts.fiscalYears.length > 1">
            <v-subheader>Select Fiscal Year</v-subheader>
            <span v-for="(item, index) in accounts.fiscalYears" :key="index">
              <v-list-item
                @click="selectFiscalYear(item.id)"
                v-if="item.id !== accounts.selectedFiscalYear.id"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </span>
          </v-list>
        </v-menu>
        <div style="margin-left: 15px; margin-top: 15px">
          {{ date.format('mmmm d, yyyy ddd') }}
        </div>
        <v-menu offset-y transition="slide-y-reverse-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-card style="width: 240px">
            <v-list>
              <v-list-item @click="$router.push({ name: 'profile' })">
                <v-list-item-action>
                  <v-icon>account_circle</v-icon>
                </v-list-item-action>
                <v-list-item-title>My Account</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({ name: 'setting' })">
                <v-list-item-action>
                  <v-icon>settings</v-icon>
                </v-list-item-action>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-action>
                  <v-icon>input</v-icon>
                </v-list-item-action>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- <v-toolbar
      dark
      color="blue darken-3"
      dense
      app
      fixed
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <v-toolbar-side-icon @click="drawer = !drawer"></v-toolbar-side-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip color="primary" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="convertDateModal = true"
              >date_range</v-icon
            >
          </template>
          <span>Date Converter</span>
        </v-tooltip>
        <v-menu offset-y transition="slide-y-reverse-transition">
          <v-btn flat slot="activator">
            {{ accounts.selectedFiscalYear.text }}
            <v-icon ml-2 v-if="accounts.fiscalYears.length > 1"
              >arrow_drop_down</v-icon
            >
          </v-btn>
          <v-list v-if="accounts.fiscalYears.length > 1">
            <v-subheader>Select Fiscal Year</v-subheader>
            <span v-for="(item, index) in accounts.fiscalYears" :key="index">
              <v-list-tile
                @click="selectFiscalYear(item.id)"
                v-if="item.id !== accounts.selectedFiscalYear.id"
              >
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile>
            </span>
          </v-list>
        </v-menu>

        <div style="margin-left: 15px; margin-top: 15px">
          {{ date.format('mmmm d, yyyy ddd') }}
        </div>
        <v-menu offset-y transition="slide-y-reverse-transition">
          <v-btn icon slot="activator">
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-card style="width: 240px">
            <v-list>
              <v-list-tile @click="$router.push({ name: 'profile' })">
                <v-list-tile-action>
                  <v-icon>account_circle</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>My Account</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="$router.push({ name: 'setting' })">
                <v-list-tile-action>
                  <v-icon>settings</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>Settings</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="logout">
                <v-list-tile-action>
                  <v-icon>input</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>Log Out</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar> -->
    <v-main>
      <v-container fluid fill-height class="grey lighten-4 pa-3">
        <v-layout>
          <transition name="router-anim">
            <router-view :key="$route.fullPath" />
          </transition>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer class="pa-3">
      <v-spacer></v-spacer>
      <div style="text-align: center; font-size: 13px">
        &copy; 2017 - {{ new Date().getFullYear() }} | Lekhangkan | Version
        {{ version }} Beta Release
      </div>
    </v-footer>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.status"
      :right="true"
      :top="true"
    >
      {{ snackbar.message }}
    </v-snackbar>

    <v-dialog v-model="convertDateModal" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Convert Date</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <v-layout row wrap>
            <v-flex xs2>
              <v-select
                v-model="date_type"
                :items="dateType"
                label="Date Type"
                :height="25"
              />
            </v-flex>
            <v-flex xs1></v-flex>
            <v-flex xs4>
              <v-text-field
                autocomplete="off"
                :label="'Enter ' + date_type + ' Date'"
                placeholder="YYYY-MM-DD"
                v-model="inputDate"
                name="name"
                v-mask="'####-##-##'"
                :height="25"
              />
            </v-flex>
            <v-flex xs3>
              <p>
                <span :class="dateColor">{{ convertedDate }}</span>
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            outline
            @click="
              (convertDateModal = false), (convertedDate = ''), (inputDate = '')
            "
          >
            Close
          </v-btn>
          <v-btn color="success" outline @click="convert">Convert</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<style lang="scss" scoped>
.v-badge--overlap .v-badge__badge {
  display: none;
}

.notification {
  .v-badge__badge {
    display: none;
  }
}

.badge {
  background: red;
  width: -60px;
  position: absolute;
  width: 14px;
  height: 15px;
  font-size: 9px;
  border-radius: 10px;
  padding-top: 2px;
  padding-left: -20px;
  padding-right: 0px;
  margin-left: -8px;
  margin-top: -1px;
}

.ibadge {
  margin-top: -13px;
  padding-left: 4px;
  font-size: 11px;
  padding-top: 1px;
}

.dgreen {
  color: green;
}

.dred {
  color: red;
}
</style>
<script>
import cookie from 'js-cookie';
import { mapState, mapActions, mapMutations } from 'vuex';
import SideBar from '@/components/SideBar';
import appPackage from './../../../../package';
import { getRolesImage } from '../../../library/helpers';

const NepaliDate = require('nepali-date');

export default {
  components: {
    SideBar,
  },
  data: () => ({
    drawer: true,
    updateRoleModal: false,
    snackbar: {},
    batches: [],
    date: new NepaliDate(),
    version: appPackage.version,
    notifications: [],
    birthDayNotification: {},
    totalCount: 0,
    fetchForRole: '',
    fiscalYears: [],
    currentFiscalYear: {},
    convertDateModal: false,
    dateType: ['AD', 'BS'],
    date_type: 'AD',
    inputDate: '',
    convertedDate: '',
    dateColor: 'green',
  }),
  mounted() {
    this.$events.listen('notification', (payload) => {
      if (typeof payload === 'object' && payload.message) {
        this.snackbar = {
          message: payload.message ? payload.message : '',
          status: payload.status ? payload.status : 'success',
          show: true,
        };
      }
    });
    this.getFiscalYear();
  },

  computed: {
    ...mapState(['user', 'accounts']),
    userRoles() {
      return this.$auth.user().roles;
    },
  },

  methods: {
    ...mapActions(['setUser']),
    ...mapMutations(['SET_FISCAL_YEAR', 'SET_FISCAL_YEARS_ALL']),
    getRolesImage,
    logout() {
      this.$storage.delete('_token');
      this.$storage.delete('_currentFiscalYear');
      this.$storage.delete('_token');
      this.$storage.delete('_permissions');
      this.$storage.delete('_user');
      this.$storage.delete('_role');

      // cookie.remove('_batch');
      cookie.remove('_company');
      this.$router.replace({ name: 'login' });
    },
    getFiscalYear() {
      // console.log('testing....fiscal Year');

      this.$rest
        .get('/api/fiscal-year')
        .then(({ data }) => {
          // console.log('get... Fiscal Year');
          let currentFiscalYear = null;
          this.fiscalYears = data.data.map((item) => {
            if (item.is_current === true) {
              currentFiscalYear = { id: item.id, text: item.name };
            }
            return { id: item.id, text: item.name };
          });
          this.SET_FISCAL_YEARS_ALL(this.fiscalYears);

          let savedFiscalYear =
            window.localStorage.getItem('_currentFiscalYear');
          if (savedFiscalYear) {
            currentFiscalYear = JSON.parse(savedFiscalYear);
          }

          if (currentFiscalYear) this.SET_FISCAL_YEAR(currentFiscalYear);
        })
        .catch((err) => {
          if (err.response)
            this.$events.fire('notification', {
              message: err.response.data.message,
              status: 'error',
            });
        });
    },
    selectFiscalYear(id) {
      let fiscalYear = this.fiscalYears.find((item) => item.id === id);
      this.SET_FISCAL_YEAR(fiscalYear);
      location.reload();
    },
    convert() {
      try {
        if (this.date_type === 'AD') {
          // convert Nepali date to Enlgish
          let nd = new NepaliDate(new Date(this.inputDate));
          this.convertedDate = nd.format('YYYY-MM-DD');
          this.dateColor = 'dgreen';
        }
        if (this.date_type === 'BS') {
          //    convert English date to Nepali
          let ed = new NepaliDate(this.inputDate).getEnglishDate();
          this.convertedDate =
            ed.getFullYear() +
            '-' +
            ed.getMonth().pad() +
            '-' +
            ed.getDay().pad();
          this.dateColor = 'dgreen';
        }
      } catch (e) {
        this.convertedDate = 'Invalid Date';
        this.dateColor = 'dred';
      }
    },
  },
};
</script>
