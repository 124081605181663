let routes = [
  {
    name: 'Inventory',
    icon: 'account_balance',
    permission: 'staff-create',
    header: true,
    children: [
      {
        name: 'Settings',
        icon: 'account_balance',
        permission: 'staff-create',
        children: [
          {
            name: 'Category',
            path: 'category',
            icon: 'category',
            permission: 'staff-create',
          },
          {
            name: 'Branch',
            path: 'warehouse-branch',
            icon: 'lan',
            permission: 'staff-create',
          },
          {
            name: 'Warehouse',
            path: 'warehouse',
            icon: 'warehouse',
            permission: 'staff-create',
          },
          {
            name: 'Unit',
            path: 'unit',
            icon: 'balance',
            permission: 'staff-create',
          },
          {
            name: 'Create Item',
            path: 'item-create',
            icon: 'edit_calendar',
            permission: 'staff-create',
          },
        ],
      },
      {
        name: 'Activity',
        icon: 'account_balance',
        permission: 'staff-create',
        children: [
          {
            name: 'Create Purchase',
            path: 'purchase',
            icon: 'request_quote',
            permission: 'staff-create',
          },
          {
            name: 'Purchase Return',
            path: 'purchase-return',
            icon: 'undo',
            permission: 'staff-create',
          },
          {
            name: 'Create Sales',
            path: 'sales',
            icon: 'point_of_sale',
            permission: 'staff-create',
          },
          {
            name: 'Sales Return',
            path: 'sales-return',
            icon: 'undo',
            permission: 'staff-create',
          },
        ],
      },
      {
        name: 'Reports',
        icon: 'account_balance',
        permission: 'staff-create',
        children: [
          {
            name: 'Items',
            path: 'item',
            icon: 'category',
            permission: 'staff-create',
          },
          {
            name: 'Purchases',
            path: 'purchase-report',
            icon: 'request_quote',
            permission: 'staff-create',
          },
          {
            name: 'Purchase Return Report',
            path: 'purchase-return-report',
            icon: 'undo',
            permission: 'staff-create',
          },
          {
            name: 'Sales Report',
            path: 'sales-report',
            icon: 'point_of_sale',
            permission: 'staff-create',
          },
          {
            name: 'Sales Return Report',
            path: 'sales-return-report',
            icon: 'undo',
            permission: 'staff-create',
          },
          {
            name: 'FIFO',
            path: 'closing-fifo-report',
            icon: 'business',
            permission: 'staff-create',
          },
          {
            name: 'Stock Report',
            path: 'stock-report',
            icon: 'business',
            permission: 'staff-create',
          },
        ],
      },
    ],
  },
];

export default routes;
