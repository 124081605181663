let routes = [{
    name: 'Dashboard',
    icon: 'dashboard',
    path: 'home',
    permission: true
},
    // {
    //     name: 'AiO',
    //     icon: 'search',
    //     path: 'aio',
    //     permission: false,
    // }
];

export default routes;