var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-img',{staticClass:"lekhangkan_img",staticStyle:{"background-size":"230px"},attrs:{"src":_vm.logo,"lazy-src":"/logo_lekh.png","aspect-ratio":10 / 9}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1),_c('v-layout',{staticClass:"lightbox white--text",attrs:{"pa-2":"","column":"","fill-height":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"shrink":""}})],1)],1),_c('v-divider'),_c('v-list',{staticClass:"mb-3",attrs:{"dense":""}},[_vm._l((_vm.nav),function(menu,key){return [(
            (menu.permission === true || _vm.$auth.can(menu.permission)) &&
            menu.children &&
            menu.children.length > 0
          )?_c('v-list-group',{key:key,attrs:{"no-action":"","prepend-icon":menu.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":menu.name}},[_vm._v(_vm._s(menu.name))])],1)]},proxy:true}],null,true)},[_vm._l((menu.children),function(child,childKey){return [(
                _vm.$auth.can(child.permission) &&
                child.children &&
                child.children.length > 0 &&
                (!child.callBackFunc || child.callBackFunc())
              )?_c('v-list-group',{key:childKey,attrs:{"sub-group":"","no-action":"","append-icon":menu.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{attrs:{"title":child.name}},[_c('v-list-item-title',[_vm._v(_vm._s(child.name))])],1)]},proxy:true}],null,true)},[_vm._l((child.children),function(c,k){return [_c('v-list-item',{key:k,attrs:{"ripple":""},on:{"click":function($event){return _vm.goto(c.path)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(c.icon ? c.icon : "remove"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(c.name))])],1)],1)]})],2):(
                (child.callBackFunc && child.callBackFunc()) ||
                (child.permission && _vm.$auth.can(child.permission))
              )?_c('v-list-item',{key:childKey,attrs:{"ripple":""},on:{"click":function($event){return _vm.goto(child.path)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(child.icon ? child.icon : "remove"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticStyle:{"display":"block","color":"#555","text-decoration":"none"},attrs:{"href":'/' + child.path},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(child.name))])])],1)],1):(
                (child.callBackFunc && child.callBackFunc()) ||
                (child.permission && _vm.$auth.can(child.permission))
              )?_c('v-list-item',{key:childKey,attrs:{"ripple":""},on:{"click":function($event){return _vm.goto(child.path)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(child.icon ? child.icon : "remove"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticStyle:{"display":"block","color":"#555","text-decoration":"none"},attrs:{"href":'/' + child.path},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(child.name))])])],1)],1):_vm._e()]})],2):(menu.permission === true || _vm.$auth.can(menu.permission))?_c('v-list-item',{key:key,attrs:{"ripple":""},on:{"click":function($event){return _vm.goto(menu.path)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(menu.icon ? menu.icon : "remove"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticStyle:{"display":"block","color":"#555","text-decoration":"none"},attrs:{"href":'/' + menu.path},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(menu.name)+" ")])])],1)],1):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }