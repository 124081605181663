<template>
  <v-flex>
    <v-card tile flat>
      <v-img
        class="lekhangkan_img"
        :src="logo"
        lazy-src="/logo_lekh.png"
        :aspect-ratio="10 / 9"
        style="background-size: 230px"
      >
        <v-layout
          slot="placeholder"
          fill-height
          align-center
          justify-center
          ma-0
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-layout>
        <v-layout pa-2 column fill-height class="lightbox white--text">
          <v-spacer></v-spacer>
          <v-flex shrink>
            <!--<div class="subheading" style="color:#444;font-weight: bold">{{$auth.user().name}}</div>-->
            <!--<div class="body-1" style="color:#444;font-weight: bold">{{$auth.user().email}}</div>-->
          </v-flex>
        </v-layout>
      </v-img>
      <v-divider />

      <!--v-if="$permissions.checkPermissions(menu.permission)"-->
      <v-list dense class="mb-3">
        <template v-for="(menu, key) in nav">
          <!-- <template v-for="(menu, key) in nav" v-if="menu.permission===true || $permissions.checkPermissions(menu.permission)"> -->
          <v-list-group
            :key="key"
            no-action
            v-if="
              (menu.permission === true || $auth.can(menu.permission)) &&
              menu.children &&
              menu.children.length > 0
            "
            :prepend-icon="menu.icon"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title :title="menu.name">{{
                  menu.name
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="(child, childKey) in menu.children">
              <v-list-group
                sub-group
                no-action
                :key="childKey"
                v-if="
                  $auth.can(child.permission) &&
                  child.children &&
                  child.children.length > 0 &&
                  (!child.callBackFunc || child.callBackFunc())
                "
                :append-icon="menu.icon"
              >
                <template v-slot:activator>
                  <v-list-item-content :title="child.name">
                    <v-list-item-title>{{ child.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template
                  style="padding-left: 50px"
                  v-for="(c, k) in child.children"
                >
                  <v-list-item ripple :key="k" @click="goto(c.path)">
                    <v-list-item-action>
                      <v-icon>{{ c.icon ? c.icon : "remove" }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ c.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-list-item
                v-else-if="
                  (child.callBackFunc && child.callBackFunc()) ||
                  (child.permission && $auth.can(child.permission))
                "
                ripple
                :key="childKey"
                @click="goto(child.path)"
              >
                <v-list-item-action>
                  <v-icon>{{ child.icon ? child.icon : "remove" }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <a
                      :href="'/' + child.path"
                      @click.prevent=""
                      style="display: block; color: #555; text-decoration: none"
                    >
                      {{ child.name }}</a
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="
                  (child.callBackFunc && child.callBackFunc()) ||
                  (child.permission && $auth.can(child.permission))
                "
                ripple
                :key="childKey"
                @click="goto(child.path)"
              >
                <v-list-item-action>
                  <v-icon>{{ child.icon ? child.icon : "remove" }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <a
                      :href="'/' + child.path"
                      @click.prevent=""
                      style="display: block; color: #555; text-decoration: none"
                    >
                      {{ child.name }}</a
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            ripple
            :key="key"
            @click="goto(menu.path)"
            v-else-if="menu.permission === true || $auth.can(menu.permission)"
          >
            <v-list-item-action>
              <v-icon>{{ menu.icon ? menu.icon : "remove" }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a
                  :href="'/' + menu.path"
                  @click.prevent=""
                  style="display: block; color: #555; text-decoration: none"
                >
                  {{ menu.name }}
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-flex>
</template>
<script>
import menus from "@/modules/Menus";
import cookie from "js-cookie";

export default {
  data: () => ({
    items: [],
  }),
  computed: {
    nav() {
      return [...this.items, ...menus];
    },
    current() {
      return this.$route.name;
    },
    logo() {
      let companyLogo =
        cookie.get("_cl") ||
        "https://eacademy.sgp1.cdn.digitaloceanspaces.com/beta/school/rmUpdLgOX9vcimXTahH65yyJgJck8sOw4mpZIs3G.png";
      return location.host === "lekhangkan.com" ||
        location.host === "service.lekhangkan.com" ||
        location.host === "lekhangkan.sahakaryatech.com"
        ? "https://eacademy.sgp1.digitaloceanspaces.com/lekhangkan/logo_lekh.png"
        : companyLogo;
    },
  },
  methods: {
    goto(path) {
      if (path) this.$router.push({ name: path });
    },
  },
};
</script>
<style lang="scss">
.lekhangkan_img {
  .v-image__image--cover {
    background-size: 270px !important;
  }
  div.v-responsive__sizer {
    padding-bottom: 50% !important;
  }
}
</style>
