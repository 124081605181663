import NavFactory from "../../../library/NavFactory";

let routes = [
    {
        name: 'Map Fee Head',
        icon: 'account_balance',
        permission: 'staff-create',
        header: true,
        children: [
            {
                name: 'Map Fee Head',
                icon: 'account_balance',
                path: 'map-fee-head',
                callBackFunc: NavFactory.isSchoolAccounting,
                permission: true,
                header: true
            }, {
                name: 'Mapping Param',
                path: 'mapping-param',
                icon: 'tune',
                permission: true,
                header: true,
            }, {
                name: 'Inventory Mapping',
                path: 'inventory-mapping',
                icon: 'inventory',
                permission: true,
                header: true,
            }
        ]
    },


    // {
    //     name: 'AiO',
    //     icon: 'search',
    //     path: 'aio',
    //     permission: false,
    // }
];

export default routes;